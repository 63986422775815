import {GET, POST} from "@/services/gateway";

const getStats = (data) => {
  return POST('dashboard/stats/google', data)
}

const getFinishedBackup = () => {
  return GET('dashboard/google/ready')
}

export {
  getStats,
  getFinishedBackup
}
