import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import LIST_USERS from '../../graphql/user/listUsers.gql'
import CREATE_USER from '../../graphql/user/createUser.gql'
import UPDATE_USER from '../../graphql/user/updateUser.gql'
import GENERATE_NEW_TEMP_PASS from '../../graphql/user/generateNewTempPassword.gql'
import LOG_IN_AS_USER from '../../graphql/user/loginAsUser.gql'

const list = ({pagination}) => apolloClient
  .query({
    query: LIST_USERS,
    variables: {
      input: {
        pagination: {
          ...pagination,
          pageSize: parseInt(pagination.pageSize)
        }
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {users}}) => users)

const create = (input) => apolloClient
  .mutate({
    mutation: CREATE_USER,
    variables: {input}
  }).then(({data: {response}}) => response)

const update = (input) => apolloClient
  .mutate({
    mutation: UPDATE_USER,
    variables: {
      input: {
        id: input.id,
        firstName: input.firstName,
        lastName: input.lastName
      }
    }
  }).then(({data: {user}}) => user)

const generateNewTempPassword = (id) => apolloClient
  .mutate({
    mutation: GENERATE_NEW_TEMP_PASS,
    variables: {
      id
    }
  }).then(({data: {response}}) => response)

const logInAsUser = (userId) => apolloClient
  .mutate({
    mutation: LOG_IN_AS_USER,
    variables: {
      input: {
        userId
      }
    }
  }).then(({data: {response}}) => response)

export {
  list,
  create,
  update,
  generateNewTempPassword,
  logInAsUser
}
