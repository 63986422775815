import {defaultPagination} from "@/services/pagination";
import {
  listBreadcrumps,
  listConversation,
  exportSnapshot
} from "@/services/api/app/office/recovery/team/data-set/conversation";

const defaultState = () => ({
  files: [],
  searchFiles: [],
  searchPagination: {
    ...defaultPagination(),
    sortBy: "snapshots.created_date_time",
    sortDescending: true
  },
  pagination: {
    ...defaultPagination(),
    sortBy: "snapshots.created_date_time",
    sortDescending: true
  },
  folderId: null,
  channelIds: [],
  breadcrumps: null,
  search: null,
  initialSearch: null,
})

export default {
  namespaced: true,

  actions: {
    listFiles({commit, state}, data) {
      return listConversation({
        ...data,
        pagination: state.pagination,
        folderId: state.folderId,
        searchTerm: state?.search?.searchTerm,
        channelIds: state.channelIds
      })
        .then(({result, pagination}) => {
          let index = Date.parse(new Date());
          const res = result.map(file => {
            return {
              ...file,
              originalId: file.id,
              id: index++
            }
          })

          commit('setFiles', res)
          commit('setPagination', pagination)
        })
    },
    searchFiles({commit, state}, data) {
      return listConversation(data)
        .then(({result, pagination}) => {
          commit('setSearchFiles', result)
          commit('setSearchPagination', pagination)
          return {result, pagination}
        })
    },
    searchSnapshotDetails({commit, state}, data) {
      return listConversation(data)
        .then(({result, pagination}) => {
          commit('setSearchFiles', result)
          commit('setSearchPagination', pagination)
          return {result, pagination}
        })
    },
    listBreadcrumps({state, commit}, {teamId, snapshotDate, channelId}) {
      if (channelId || (state.folderId && teamId && snapshotDate)) {
        return listBreadcrumps({
          teamId,
          snapshotDate,
          channelId,
          folderId: state.folderId,
        })
          .then(result => {
            commit('setBreadcrumps', result);
          })
      } else {
        commit('setBreadcrumps', []);
        return Promise.resolve();
      }
    },
    exportSnapshot({commit}, data) {
      return exportSnapshot(data);
    }
  },

  mutations: {
    setFiles(state, files) {
      state.files = files;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setSearchFiles(state, files) {
      state.searchFiles = files;
    },
    setSearchPagination(state, pagination) {
      state.searchPagination = pagination;
    },
    resetSearchPagination(state) {
      state.searchPagination = {
        ...defaultPagination(),
        sortBy: "snapshots.created_date_time",
        sortDescending: true
      };
    },
    setFolderId(state, folderId) {
      state.folderId = folderId;
    },
    setChannelIds(state, channelIds) {
      state.channelIds = channelIds;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setBreadcrumps(state, breadcrumps) {
      state.breadcrumps = breadcrumps;
    },
    setInitialSearch(state, initialSearch) {
      state.initialSearch = initialSearch
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  state: defaultState()
}
