import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import LIST_JOBS from '../../graphql/job/listJobs.gql'
import GET_JOB from '../../graphql/job/getById.gql'
import JOBS_SUMMARY from '../../graphql/job/jobsSummary.gql'
import CANCEL_JOB from '../../graphql/job/cancelJob.gql'
import RETRY_JOB from '../../graphql/job/retryJob.gql'

const list = ({pagination, filter}) => apolloClient
  .query({
    query: LIST_JOBS,
    variables: {
      input: {
        pagination: {
          ...pagination,
          pageSize: parseInt(pagination.pageSize)
        },
        filter
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {jobs}}) => jobs)

const getById = (id) => apolloClient
  .query({
    query: GET_JOB,
    variables: {id},
    fetchPolicy: 'no-cache'
  }).then(({data: {job}}) => job)

const getSummary = (dateFrom) => apolloClient
  .query({
    query: JOBS_SUMMARY,
    variables: {
      input: {
        dateFrom
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {data}}) => data)

const cancel = (input) => apolloClient
  .mutate({
    mutation: CANCEL_JOB,
    variables: {
      input
    }
  }).then(({data: {job}}) => job)

const retry = (input) => apolloClient
  .mutate({
    mutation: RETRY_JOB,
    variables: {
      input
    }
  }).then(({data: {job}}) => job)

export {
  list,
  getById,
  getSummary,
  cancel,
  retry
}
