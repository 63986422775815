import {
  getUserDetails,
  createBackup,
  listSnapshots,
} from "@/services/api/app/google/recovery/gmail/gmail";
import folders from "@/store/app/google/recovery/gmail/data-set/file";
import mails from "@/store/app/google/recovery/gmail/data-set/file";
import exports from "@/store/app/google/recovery/gmail/exports";
import restores from "@/store/app/google/recovery/gmail/restores";
import backups from "@/store/app/google/recovery/gmail/backups";
import user from "@/store/app/google/recovery/gmail/user";

const defaultState = () => ({
  userMap: {},
});

export default {
  namespaced: true,
  actions: {
    getUserDetails({commit}, userId) {
      return getUserDetails(userId)
        .then(user => {
          commit('setUser', user);
          return user;
        })
    },
    createBackup({commit}, userId) {
      return createBackup(userId);
    },
    listSnapshots({commit}, {userId, date, dateTo}) {
      return listSnapshots({userId, date, dateTo});
    },
  },

  mutations: {
    setUser(state, user) {
      state.userMap = {
        ...state.userMap,
        [user.userId]: user
      };
    },
  },

  getters: {
    getUserById: (state) => (id) => {
      return state.userMap[id];
    },
  },

  state: defaultState(),

  modules: {
    folders,
    mails,
    exports,
    restores,
    backups,
    user,
  }
}
