import {GET, POST} from "@/services/gateway";

const list = ({pagination, search, filter}) => {
  return POST('google/user/list', {pagination, search, filter});
}

const getStatus = () => {
  return GET('google/user/status');
}

const refresh = () => {
  return GET('google/user/refresh');
}

const backupAll = () => {
  return GET('google/user/backup/all')
}

const protectAll = () => {
  return POST('google/user/protect');
}

const unprotectAll = () => {
  return POST('google/user/unprotect');
}
const changeStatus = ({status, id}) => {
  return POST('google/user/userstatus/change', {status, id});
}

const exportSnapshot = (filter, search, pagination) => {
  return POST('google/user/export', {filter, search, pagination})
}

export {
  list,
  refresh,
  getStatus,
  protectAll,
  unprotectAll,
  backupAll,
  changeStatus,
  exportSnapshot
}
