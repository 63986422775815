import {list, getSeatsSummary} from "@/services/graphql/tenant";
import {defaultPagination} from "@/services/pagination";
import {stripPagination} from "@/services/graphql/common";
import {getTenantDetails} from "@/services/graphql/tenantDetails";

const defaultState = () => ({
  list: [],
  map: {},
  pagination: {
    ...defaultPagination(),
    pageSize: 0,
    pageNumber: 1,
    sortBy: null
  },
  filter: {},
  summary: {},
  summaryFilter: 'LAST_24_H',
  tenant: {},
});

export default {
  namespaced: true,

  actions: {
    list({commit, state}) {
      return list({pagination: stripPagination(state.pagination), filter: state.filter})
        .then(({result, pagination}) => {
          commit('set', result)
          commit('setPagination', pagination)
        })
    },
    getSeatsSummary({commit, state}, dateFrom) {
      return getSeatsSummary(dateFrom)
        .then(data => {
          commit('setSummary', data)
          commit('setSummaryFilter', dateFrom)
        })
    },
    getTenantDetails({commit, state}, id) {
      return getTenantDetails(id)
        .then(tenantDetails => {
          commit('setTenantDetails', tenantDetails)
        })
    },
  },

  mutations: {
    set(state, tenants) {
      state.list = tenants;

      state.map = tenants.reduce((acc, tenant) => ({
        ...acc,
        [tenant.id]: tenant
      }), {})
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        pageSize: state.pagination.pageSize,
        sortBy: null,
        sortDescending: false
      };
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    setSummaryFilter(state, summaryFilter) {
      state.summaryFilter = summaryFilter;
    },
    resetFilter(state) {
      state.filter = {};
    },
    setTenantDetails(state, tenant) {
      state.tenant = {
        ...state.tenant,
        [tenant.tenant.Id]: {
          ...tenant
        }
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  getters: {
    getTenantById: (state) => (id) => {
      return state.tenant[id]
    }
  },

  state: defaultState()
}
