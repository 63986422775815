import {updateAutoAdd, list} from "@/services/api/app/seat-management/backupsettings";

const defaultState = () => ({
  autoAdd: {
    userSeats: true,
    siteSeats: true,
    teamSeats: true,
    mailboxSeats: true,
  }
});

export default {
  namespaced: true,

  actions: {
    list({commit}) {
      return list()
        .then((data) => {
          if (data?.autoAdd) {
            commit('setAutoAdd', data.autoAdd)
          }
        })
    },
    updateAutoAdd({commit}, data) {
      return updateAutoAdd(data)
        .then(({autoAdd}) => {
          commit('setAutoAdd', autoAdd)
        })
    }
  },

  mutations: {
    setAutoAdd(state, autoAdd) {
      state.autoAdd = autoAdd
    }
  },


  getters: {
    //seatType: usersSeat, sitesSeat, teamsSeat, mailboxSeat
    getAutoAddSettingByType: (state) => (seatType) => {
      return state.autoAdd && state.autoAdd[seatType];
    },
  },

  state: defaultState()
}
