import recovery from "@/store/app/office/recovery/recovery";
import stats from "@/store/app/office/stats";
import report from "@/store/app/office/report";
import userReport from "@/store/app/office/userReport";
import backups from "@/store/app/office/backups";
import allEventLogs from "@/store/app/office/activity-logs/allEventLogs";

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    stats: stats,
    report,
    userReport,
    backups,
    allEventLogs,
  }
}
