<template>
  <div class="i-dropdown" :class="{'disabled': disabled}">
    <div class="field-label">
      {{ label }}
    </div>

    <multiselect
      label="label"
      :class="{[textTransform]: true}"
      :value="selectedValue"
      :options="options"
      :placeholder="placeholder"
      :multiple="multiSelect"
      :searchable="false"
      :disabled="disabled"
      @select="onSelect"
      @remove="onRemove"
    >
      <template v-slot:clear>
        <font-awesome-icon
          v-if="selectedValue && clearable"
          class="clear-btn btn-icon icon-clickable"
          icon="fa-duotone fa-xmark"
          @click.stop="clearAll()"
        />
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: "IDropdown",
  components: {Multiselect},
  props: {
    options: {
      type: Array
    },
    value: {},
    label: {
      type: String,
      default: 'Choose'
    },
    placeholder: {
      type: String,
      default: 'Choose'
    },
    disabled: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },

    multiSelect: {
      type: Boolean,
      default: false
    },
    textTransform: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      selected: null
    }
  },

  computed: {
    selectedValue() {
      if (!this.value) {
        return null
      }

      if (this.multiSelect) {
        return this.options.filter(option => this.value.includes((option.value)))
      }

      return this.options.find(option => option.value === this.value)
    }
  },
  methods: {
    onSelect(data) {
      let dataValue
      if (this.multiSelect) {
        dataValue = [...this.value || [],data.value]
      } else {
        dataValue = data.value
      }
      this.$emit('input', dataValue)
    },

    clearAll() {
      this.$emit('input', null)
    },

    onRemove(data) {
      if (this.multiSelect) {
        let values = this.value.filter(val => data.value !== val)
        if (values.length === 0){
          this.$emit('input', null)
        } else {
          this.$emit('input', values)
        }
      } else{
        this.$emit('input', null)
      }
    }
  },

}
</script>

<style lang="scss">
@import "IDropdown";
</style>
