import {defaultPagination} from "@/services/pagination";
import {listBreadcrumps, listSnapshotDetails} from "@/services/api/app/google/recovery/contact/data-set/file";

const defaultState = () => ({
    files: [],
    searchFiles: [],
    searchPagination: {
        ...defaultPagination(),
        sortBy: 'name',
        sortDescending: false
    },
    pagination: {
        ...defaultPagination(),
        sortBy: 'name',
        sortDescending: false
    },
    folderId: null,
    breadcrumps: [],
    search: null,
    initialSearch: null,
})

export default {
    namespaced: true,

    actions: {
        listSnapshotDetails({commit, state}, data) {
            return listSnapshotDetails({
                ...data,
                pagination: state.pagination,
                folderId: state.folderId,
                searchTerm: data.searchTerm ? data.searchTerm : state?.search?.searchTerm
            })
                .then(({result, pagination}) => {
                    let index = Date.parse(new Date());
                    const res = result.map(file => {
                        return {
                            ...file,
                            originalId: file.id,
                            id: index++
                        }
                    })

                    commit('setFiles', res)
                    commit('setPagination', pagination)
                })
        },
        searchSnapshotDetails({commit}, data) {
            return listSnapshotDetails(data)
                .then(({result, pagination}) => {
                    commit('setSearchFiles', result)
                    commit('setSearchPagination', pagination)
                    return {result, pagination}
                })
        },
        listBreadcrumps({state, commit}, data) {
            if (state.folderId && data.userId && data.snapshotDate) {
                return listBreadcrumps({
                    ...data,
                    folderId: state.folderId
                })
                    .then(result => {
                        commit('setBreadcrumps', result);
                    })
            } else {
                commit('setBreadcrumps', []);
                return Promise.resolve();
            }

        },
    },

    mutations: {
        setFiles(state, files) {
            state.files = files;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setSearchFiles(state, files) {
            state.searchFiles = files;
        },
        setSearchPagination(state, pagination) {
            state.searchPagination = pagination;
        },
        resetSearchPagination(state) {
            state.searchPagination = {
                ...defaultPagination(),
                sortBy: 'name',
                sortDescending: false
            };
        },
        setFolderId(state, folderId) {
            state.folderId = folderId;
        },
        setSearch(state, search) {
            state.search = search
        },
        setBreadcrumps(state, breadcrumps) {
            state.breadcrumps = breadcrumps;
        },
        resetState(state) {
            Object.assign(state, defaultState())
        },
        setInitialSearch(state, initialSearch){
            state.initialSearch = initialSearch
        }
    },

    state: defaultState()
}
