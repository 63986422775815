<template>
  <div
      ref="tooltip"
      class="i-tooltip"
      :class="{'showUp' : showUpTooltip, 'showLeft' : showLeftTooltip,}"
      @mouseenter="onHover"
  >
    <slot name="tooltip-wrapper"></slot>

    <div
        ref="arrow-tooltip"
        class="arrow-tooltip"
        :class="[{'show-tooltip' : !showTooltip}]"
    />
    <div
        ref="tooltip-text"
        class="tooltip-text"
        :class="[theme, fontWeight, {'show-tooltip' : !showTooltip}]"
        :style="{
            'top': offsetTop + 'px',
            'left': offsetLeft + 'px',
        }"
    >
      <slot name="tooltip">
        {{ text }}
      </slot>
    </div>
  </div>
</template>

<script>
import IGenerateImage from "@/components/IGenerateImage.vue";

export default {
  name: 'ITooltip',
  components: {IGenerateImage},
  data: () => ({
    offsetTop: 0,
    offsetLeft: 0,
    showUpTooltip: false,
    showLeftTooltip: false,
    showTooltip: false,
  }),
  props: {
    text: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: '', // default: white, possible: dark
    },
    fontWeight: {
      type: String,
      default: '', // default: normal, possible: bold
    },
  },

  methods: {
    onHover() {
      this.showTooltip = true;
      let tooltip = this.$refs['tooltip'];
      let tooltipText = this.$refs['tooltip-text'];

      let sumHeight = tooltip.getBoundingClientRect().top + tooltip.offsetHeight + tooltipText.offsetHeight + 10 // 10px for before element;
      let sumWidth = tooltip.getBoundingClientRect().left + tooltip.offsetWidth + (tooltipText.offsetWidth / 2)

      if (sumWidth > window.innerWidth) {
        this.showLeftTooltip = true;
        this.offsetLeft = tooltip.getBoundingClientRect().left - tooltipText.offsetWidth + tooltip.offsetWidth;
      } else {
        this.showLeftTooltip = false;
        this.offsetLeft = tooltip.getBoundingClientRect().left - (tooltipText.offsetWidth / 2) + (tooltip.offsetWidth / 2);
      }

      if (sumHeight > window.innerHeight) {
        this.showUpTooltip = true;
        this.offsetTop = tooltip.getBoundingClientRect().top - tooltipText.offsetHeight - 5;
      } else {
        this.showUpTooltip = false;
        this.offsetTop = tooltip.getBoundingClientRect().top + tooltip.offsetHeight + 5;
      }
    },

    onScroll() {
      this.showTooltip = false;
    }
  },

  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
}
</script>

<style lang="scss">
@import "ITooltip";
</style>
