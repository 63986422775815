<template>
  <div class="i-action-links" :class="{'disabled-icon' : isDisabled}">
    <span
      v-if="items"
      v-for="item in items"
    >
      <router-link
        v-if="!isDisabled"
        :to="goToUserDetailsPage(item?.pageName, userId, siteId, teamId, id, item?.dataSet)"
        @click.native.stop
      >
        <i-tooltip :text="item.tooltipLabel" font-weight="bold" theme="dark">
          <template v-slot:tooltip-wrapper>
            <i-generate-image :image="item?.icon"/>
          </template>
        </i-tooltip>
      </router-link>

      <i-tooltip v-else  :text="item.tooltipLabel" font-weight="bold" theme="dark">
          <template v-slot:tooltip-wrapper>
            <i-generate-image :image="item?.icon"/>
          </template>
        </i-tooltip>

    </span>
  </div>
</template>

<script>
import ITooltip from "@/components/ITooltip.vue";
import IGenerateImage from "@/components/IGenerateImage.vue";

export default {
  name: 'IActionLinks',
  components: {IGenerateImage, ITooltip},
  props: {
    items: {
      type: Array
    },
    userId: {
      type: String
    },
    id: {
      type: String
    },
    siteId: {
      type: String
    },
    teamId: {
      type: String
    },
    dataSet: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    goToUserDetailsPage(name, userId, siteId, teamId, sharedDriveId, dataSet) {
      if (!this.isDisabled) {
        let params = {
          dataSet: dataSet ? dataSet : null
        }

        if (userId) {
          params.userId = userId
        } else if (siteId) {
          params.siteId = siteId
        } else if (teamId) {
          params.teamId = teamId
        } else if (this.items[0].seatType === 'SharedDrive') {
          params.sharedDriveId = this.id
        }

        return {
          name,
          params
        }
      }

    },
  }
}
</script>

<style lang="scss">
@import "IActionLinks";
</style>
