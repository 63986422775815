import {listEventLogs, exportEventLogs, getJobDetails} from "@/services/api/common/report";
import {defaultPagination} from "@/services/pagination";
import {
  getDataSetLabel,
  getBackupJobModule,
  getJobEventStatus,
  getJobSourcetype,
  getTypeName
} from "@/services/enums";

const defaultState = () => ({
  list: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'startTime',
  },
  jobMap: {},
});

export default {
  namespaced: true,

  actions: {
    list({commit, state}, data) {
      return listEventLogs({
        ...data,
        pagination: state.pagination
      })
        .then(({result, pagination}) => {
          commit('setAllEventLogs', result)
          commit('setPagination', pagination)
        })
    },
    export({commit, state}, data) {
      return exportEventLogs(data)
    },
    getJobDetails({commit}, id) {
      return getJobDetails(id)
        .then(job => {
          const newJob = {
            ...job,
            _id: job.id,
            type: getTypeName(job.event),
            job_request_details: job.jobRequestDetails,
            module: getBackupJobModule(job.dataType, 1),
            data_type: getDataSetLabel(job.module),
            status: getJobEventStatus(job.status),
            source_type: getJobSourcetype(job.sourceType),
            error_message: job.errorMessage,
            start_time: job.timestamp,
            stack_trace: job.stackTrace,
            tenant: {
              Id: job.tenantId
            }
          }

          commit('setJob', newJob);
          return newJob;
        })
    },
  },

  mutations: {
    setAllEventLogs(state, allEventLogs) {
      state.list = allEventLogs;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'startTime',
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
    setJob(state, job) {
      state.jobMap = {
        ...state.jobMap,
        [job.id]: job
      };
    },
  },

  getters: {
    getById: (state) => (id) => {
      return state.jobMap[id]
    }
  },

  state: defaultState(),

  modules: {},

}
