import {defaultPagination} from "@/services/pagination";
import {listRestores,} from "@/services/api/app/office/recovery/restore";

const defaultState = () => ({
    restores: [],
    pagination: {
        ...defaultPagination(),
        sortBy: 'initiatedAt'
    },
});

export default {
    namespaced: true,
    actions: {
        listRestores({commit, state}) {
            return listRestores({
                pagination: state.pagination,
                sourceType: 'TEAM',
                dataTypes: ['DRIVE', 'CONVERSATIONS']
            })
                .then(({result, pagination}) => {
                    commit('setRestores', result)
                    commit('setPagination', pagination)
                })
        },
    },

    mutations: {
        setRestores(state, restores) {
            state.restores = restores;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },

        resetPagination(state, {keepSort}) {
            if (keepSort) {
                state.pagination = {
                    ...defaultPagination(),
                    sortBy: state.pagination.sortBy,
                    sortDescending: state.pagination.sortDescending,
                }
            } else {
                state.pagination = {
                    ...defaultPagination(),
                    sortBy: 'name',
                    sortDescending: false
                };
            }
        },

        resetState(state) {
            Object.assign(state, defaultState())
        }
    },

    state: defaultState(),
}
