<template>
  <div class="i-seat-multi-picker">

    <i-multi-picker
      label-counter="seat(s)"
      :label="label"
      :value="value"
      :fetch-options="fetchSeats"
      option-label="name"
      option-value="id"
      @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {list as listMicrosoftSeats} from "@/services/api/app/seat-management/seat";
import {list as listGoogleSeats} from "@/services/api/app/google/seat-management/seat";
import IMultiPicker from "@/components/forms/IMultiPicker.vue";

export default {
  name: 'ISeatMultiPicker',
  components: {IMultiPicker},
  props: {
    value: {},
    label: {
      type: String,
      default: 'Seats'
    },
    placeholder: {
      type: String,
      default: 'Search all seats'
    },
    providerTenant: {
      type: String,
      default: 'MICROSOFT'
    }
  },

  data() {
    return {
      selected: null,
      seats: []
    }
  },

  methods: {
    fetchSeats({search}) {
      let list = this.providerTenant === 'MICROSOFT' ? listMicrosoftSeats : listGoogleSeats

      return list({
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
        .then((data) => {
          return data
        })
    },
  },


}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
