import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import GET_TENANT_DETAILS from '../../graphql/tenant/getTenantDetails.gql';

const getTenantDetails = (id) => apolloClient
  .query({
    query: GET_TENANT_DETAILS,
    variables: {id},
    fetchPolicy: 'no-cache'
  }).then(({data: {tenantDetails}}) => tenantDetails)


export {
  getTenantDetails,
}
