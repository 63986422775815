import {GET, POST} from "@/services/gateway";

const list = ({pagination, search, filter}) => {
  return POST('seat/site/list', {pagination, search, filter})
}

const getStatus = () => {
  return GET('seat/site/status')
}

const refresh = () => {
  return GET('seat/site/refresh')
}

const backupAll = () => {
  return GET('seat/site/backup')
}

const protectAll = () => {
  return POST('seat/site/protect')
}


const unprotectAll = () => {
  return POST('seat/site/unprotect')
}

const changeStatus = ({status, id}) => {
  return POST('seat/sitestatus/change', {status, id});
}

const exportSnapshot = (filter, search, pagination) => {
  return POST('seat/site/export', {filter, search, pagination})
}


export {
  list,
  refresh,
  backupAll,
  getStatus,
  protectAll,
  unprotectAll,
  changeStatus,
  exportSnapshot
}
