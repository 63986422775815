<template>
  <div class="i-search-organisation-picker">
    <div class="field-label">
      {{ label }}
    </div>

    <multiselect
      v-model="selectedValue"
      label="superAdminFullName"
      trackBy="Id"
      :options="organisations"
      :multiple="false"
      :placeholder="placeholder"
      :loading="isLoading"
      @search-change="onSearch"
      @select="onSelect"
      @remove="onRemove"
      :custom-label="customLabel"
    >
      <template v-slot:clear>
        <font-awesome-icon
          v-if="value && clearable"
          class="clear-btn btn-icon icon-clickable"
          icon="fa-duotone fa-xmark"
          @click.stop="clearAll()"
        />
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {listOnlyTenants} from "@/services/graphql/tenant";
import {defaultPagination} from "@/services/pagination";
import {debounce} from "@/services/common";

export default {
  name: 'ISearchOrganisationPicker',
  components: {Multiselect},
  props: {
    value: {},
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Search organisations'
    },
    clearable: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      selectedValue: null,
      organisations: [],
      isLoading: false,
      searchTerm: null
    }
  },

  computed: {
    customLabel() {
      return (option) => {
        return `${option.superAdminFullName}, Tenant id: ${option.Id}, User id: ${option.superAdmin.user.Id}, Email: ${option.superAdmin.user.EmailAddress}`;
      }
    }
  },

  methods: {
    onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      this.isLoading = true;
      this.organisations = [];
      this.loadOrganisations(searchTerm)
    },
    loadOrganisations(search = null) {
      return listOnlyTenants({
        pagination: {
          ...defaultPagination(),
          pageSize: 30,
          sortBy: 'Name',
          sortDescending: false
        },
        filter: {
          search
        }
      })
        .then(({result, pagination}) => {
          this.organisations = result.map(organisation => ({
            ...organisation,
            superAdminFullName: `${organisation.superAdmin.user.Name} ${organisation.superAdmin.user.Surname}`,
          }));

          this.isLoading = false;
        })
    },
    onSelect(data) {
      this.$emit('input', data.Id)
    },
    onRemove(data) {
      this.$emit('input',
        this.value.filter(val => val !== data.Id))
    },
    clearAll() {
      this.selectedValue = null;
      this.$emit('input', null)
    }
  },

  created() {
    this.onSearch = debounce(this.onSearch, 300);
    this.loadOrganisations();
  }
}
</script>

<style lang="scss">
@import "ISearchOrganisationPicker.scss";
</style>
