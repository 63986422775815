const iEmptyData = (data, emptyData = 'NA') => {
  try {
    if ((Array.isArray(data) && !data.length)  || (!data.trim()) || data === null || data === 'null' || data === undefined || data === "undefined") {
      return emptyData
    }
    return  data
  } catch (e) {
    return data
  }
}

export {
  iEmptyData,
}
