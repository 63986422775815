import Vue from 'vue'
import MgmtApp from './MgmtApp.vue'
import router from './router/mgmt'
import store from './store/mgmt'

import './boot/fontawesome';
import './boot/common';
import './boot/filters';
import './boot/vuelidate';
import './boot/notifications';

import {iEmptyData} from './filter/iEmptyData'
import {iCapitalizeString} from './filter/iCapitalizeString'
import {iEnumConverter} from './filter/iEnumConverter'
import {iSharedDrivesConverter} from "@/filter/iSharedDrivesConverter";

Vue.config.productionTip = false

Vue.prototype.$hasListener = function $hasListener(listenerName) {
  let component = this;
  while (component) {
    if (component.$listeners[listenerName]) {
      return true;
    }
    component = component.$parent;
  }

  return false;
};

Vue.filter('iEmptyData', iEmptyData);
Vue.filter('iCapitalizeString', iCapitalizeString);
Vue.filter('iEnumConverter', iEnumConverter);
Vue.filter('iSharedDrivesConverter', iSharedDrivesConverter);

new Vue({
  router,
  store,
  render: h => h(MgmtApp)
}).$mount('#app')
